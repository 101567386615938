import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import trackerApi from "api/tracker";

const AccountScreenRecent = ( { userId } ) => {
    const [result, setResult] = useState(null);

    const baseImg = `https://res.cloudinary.com/checkmash/image/upload`;

    const getResult = async () => {
        const response = await trackerApi.get(`/recent/ticks/${userId}`);
    
        setResult(response.data);
    };
    useEffect(() => {
        getResult();
    }, [userId]);
    
    if (!result || result.length === 0) {
        return null;
    }

    return (
        <div className="recent">
            <h2>Recent Activity</h2>
            {result.map(item => {
                return (
                    <div className="itemBox" key={item.tickId}>
                        <Link to={`/items/${item.itemId}`}>
                            <img src={item.has_image = 1 ? `${baseImg}/v1632671325/items/${item.path}.jpg` : `${baseImg}/v1632666502/categories/png/${item.itemTypeId}.png` } alt={item.itemName}></img>
                            <p>{item.itemName}</p>
                        </Link>
                    </div>
              )})} 
          </div>
      );
};

export default AccountScreenRecent;