import React, { useState, useContext } from 'react';
import { UserContext } from "context/UserContext";
import trackerApi from "api/tracker";
import Add from "icons/Add";
import CheckCircle from "icons/CheckCircle";
import Bookmark from "icons/Bookmark";
import BookmarkOutline from "icons/BookmarkOutline";

const ItemScreenUnchecked = ( { itemId, initialprogressState } ) => {
    const { userId }  = useContext(UserContext);

    const [checkState, setCheckState] = useState(false);
    const [progressState, setProgressState] = useState(initialprogressState);
    
  const getResultItemCRUD = async () => {
    const response = await trackerApi.post(`ticks/${itemId}/${userId}/${checkState}`);
    // setCheckStatus(!checkStatus);
    setCheckState(response.data);
  };

  const getResultProgressCRUD = async () => {
    const response = await trackerApi.post(`progress/${itemId}/${userId}/${progressState}`);
    // setProgressStatus(!progressStatus);
    setProgressState(response.data);
  };

  return (
    <div className="itemInfoFriends">
        <div className="itemInfoAction"
        onClick={() => {
            getResultItemCRUD()
        }}
        >
            {checkState === false ? <Add /> : <CheckCircle fill="green"/>}
            <p>{checkState === false ? `Check Item` : `Item Checked!`}</p>
        </div>
        {checkState === false ?
            <div className="itemInfoAction"
            onClick={() => {
                getResultProgressCRUD()
            }}>
                {progressState === false ? <BookmarkOutline/> : <Bookmark fill="green"/>}
                <p>{progressState === false ? "Bookmark Item" : "Bookmarked"}</p>      
            </div>
        : null }
        {checkState === true && progressState === true ?
            <div className="itemInfoAction"
            onClick={() => {
                getResultProgressCRUD()
            }}>
             <p>You were tracking this item but it is now checked, remove tracking?</p>
            </div>
        : null}
    </div>
  );

   
};

export default ItemScreenUnchecked;