import React, { useState, useEffect } from "react";
import trackerApi from "api/tracker";

const CreateDropdown = ( { setListType }) => {
    const [result, setResult] = useState(null);

    const getResult = async () => {
    const response = await trackerApi.get('/types');
    setResult(response.data);
    };

    useEffect(() => {
        getResult();
    }, []);

    if (!result) { 
    return null;
    }

    return (
        <select defaultValue="0" onChange={e => setListType({listTypeId: Number(e.target.value), listTypeName: e.target[e.target.selectedIndex].text})}>
            <option key="0" value="0"  disabled>Please Select...</option>
            {result.map(type => {
            return (
                <option key={type.id} value={type.id}>{type.field}</option>
            )
            })}
        </select>
    );
}

export default CreateDropdown;