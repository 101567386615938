import React from 'react';
import Search from 'icons/Search';

const Searchbar = ( { search, setSearch, placeholder, className }) => {
  return (
      <div className={className}>
        <Search />
        <input
        value={search}
        name="Search"
        placeholder={placeholder}
        onChange={e => setSearch(e.target.value)}
        autoComplete="off"
      />
      </div>
     
  );
};

export default Searchbar;
