import React, { useState, useEffect } from 'react';
import trackerApi from "api/tracker";

const UserScreenListCounter = ( { listId, userId } ) => {
    const [result, setResult] = useState(null);

    const getResult = async () => {
    const response = await trackerApi.get(`/profile/${userId}/lists/${listId}`);

    setResult(response.data[0]);
};
useEffect(() => {
    getResult();
}, []);

if (!result) { 
    return null;
}

const progressPercentage = Math.round(result.tickcount / result.listcount * 100);
const colorStatus = (result.tickcount === result.listcount ? 'gold' : 'rgba(0, 122, 255, 1)');

  return (
    <div className="ListCounter">
        <p>{result.list_name} {result.subtitle}</p>
       
            <div className="counterResults">
            <p>
        {(result.tickcount === result.listcount
            ? 'Complete!'
            : `${result.tickcount} out of ${result.listcount}`
        )}
        </p>
        <div className="listProgress">
            <div className="listProgressBar" style={{"width" : `${progressPercentage}%`, "backgroundColor": colorStatus }}>{progressPercentage}%</div>
            </div>
        </div>
    </div>
  );
};

export default UserScreenListCounter;