import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import trackerApi from "api/tracker";

const AccountScreenTrackingWith = ( { progressId, userId } ) => {
    const [result, setResult] = useState(null);

    const getResult = async () => {
        const response = await trackerApi.get(`/progress/${progressId}/user/${userId}`);
    
        setResult(response.data);
    };
    useEffect(() => {
        getResult();
    }, [userId]);
    
    if (!result || result.length < 1) { 
        return null;
    }
    else {
        return (
            result.map(user => {
                return (
                    <Link to={`/users/${user.uName}`}>
                        <p className="trackingWith" key={progressId+userId}>with @{user.uName}</p>
                    </Link>
            )}));
};
};

export default AccountScreenTrackingWith;