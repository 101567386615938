import React from 'react';
import 'screens/HomeScreen.css';

const HomeScreenFeedDateTime = ( { updatedAt } ) => {
    const checkDateTime = new Date(updatedAt);
    const checkHours = checkDateTime.getHours();
    const checkMins = checkDateTime.getMinutes();
    const checkDays = checkDateTime.getDate();
    const checkDate = new Date(checkDateTime).setHours(0,0,0,0);


    const currentDateTime = new Date(); 
    // const currDate = new Date().toLocaleString();
    const currentHours = currentDateTime.getHours();
    const currentMins = currentDateTime.getMinutes();
    const currentDays = currentDateTime.getDate();
    const currentDate = new Date(currentDateTime).setHours(0,0,0,0); 

  return (
    <div className="feedDateTime">
        <p>
            {currentDate > checkDate
            ? `${currentDays-checkDays} ${currentDays-checkDays < 2 ? `day` : `days`} ago`
            : currentHours > checkHours
                ? `${currentHours-checkHours} ${currentHours-checkHours < 2 ? `hour` : `hours`} ago`
                : `${currentMins-checkMins < 2 ? `Just now` : `${currentMins-checkMins} minutes ago`}`
        }
        </p>
    </div>
    );

};

export default HomeScreenFeedDateTime;