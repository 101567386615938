import React, { useState, useContext } from 'react';
import { UserContext } from '../src/context/UserContext';
import Searchbar from '../src/components/Searchbar';
import SearchResults from '../src/components/SearchResults';
import Menu from '../src/icons/Menu';
import Add from '../src/icons/Add';
import Home from '../src/icons/Home';
import { useTheme } from './context/ThemeContext';
import Day from './icons/Day';
import Night from './icons/Night';
import Profile from '../src/icons/Profile';
import Logo from './logos/Logo.js';

const HeaderB = () => {
  // const userId = useContext(UserContext);
  const { userName } = useContext(UserContext);
  const { theme, toggleTheme } = useTheme();
  const [search, setSearch] = useState('');

return (
  <div className="navtopB">
    <div className="headerLeftB">
       <Logo theme={theme}/>
    </div>
    <div className="headerMiddleB">
      <Searchbar
        setSearch={setSearch}  
        search={search}  
        placeholder="Search Seendone..."
        className="searchbar"
      />
      {search.length > 2 && <SearchResults search={search} setSearch={setSearch}/>}
    </div>
    <div className="headerRightB">
      <a href="/" className="nav_responsive_500">
        <Home className="svg_nav"/>
      </a>
      <a href="/create" className="nav_responsive_500">
        <Add className="svg_nav"/>
      </a>
      <div onClick={toggleTheme}>
      {theme === "dark" ? <Day /> : <Night />}
      </div>
      <a href={'/users/' + userName}>
        <Profile className="svg_nav"/>
      </a>
      <a href="/">
        <Menu className="svg_nav"/>
      </a>
    </div>
  </div>
);
};

export default HeaderB;