import React, { useState, useEffect, useContext } from 'react';

import trackerApi from "../api/tracker";
// import { UserContext } from '../context/UserContext';
import { useParams } from "react-router-dom";
import './AccountScreen.css';

import AccountScreenTotals from '../components/Account/AccountScreenTotals';
import AccountScreenRecent from '../components/Account/AccountScreenRecent';
import AccountScreenTracking from '../components/Account/AccountScreenTracking';
import AccountScreenCompleted from '../components/Account/AccountScreenCompleted';

const AccountScreen = () => {
    // const userId = useContext(UserContext);
    const { userName } = useParams();
    const [result, setResult] = useState(null);
    const [title, setTitle] = useState(document.title);

    document.title = title;

    const getResult = async () => {
    const response = await trackerApi.get(`/users/${userName}`);

    setResult(response.data[0]);
    setTitle(response.data[0].fname);
};

useEffect(() => {
  getResult();
}, [userName]);

if (!result) { 
  return null;
}

const baseImgUrl = `https://res.cloudinary.com/checkmash/image/upload`;
const userImg = `${baseImgUrl}/v1632660254/users/${result.id}.jpg`;

  return (
    <div className="AccountScreen">
        <div className="top">
            <div className="topImage">
                <img className="userImg" src={userImg} alt={result.uName}></img>
                <p>{result.fname} {result.lname}</p>
            </div>
            <AccountScreenTotals userId={result.id} />
        </div>
        <AccountScreenRecent userId={result.id}/>
        <AccountScreenTracking userId={result.id}/>
        <AccountScreenCompleted userId={result.id}/>
    </div>
  );
};

export default AccountScreen;