import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import trackerApi from "api/tracker";

const ItemScreenListAppearances = ( { itemId } ) => {
    const [result, setResult] = useState(null);

    const getResult = async () => {
        const response = await trackerApi.get(`/full_lists/${itemId}`);
        setResult(response.data);
};

useEffect(() => {
    getResult();
}, []);

if (!result || result.length < 1) { 
    return null;
}

const baseImgUrl = `https://res.cloudinary.com/checkmash/image/upload`;

    return (
        <div className="itemInfoFriends">
            <div className="itemInfoFriendsImages">
            {result.slice(0, 3).map(list => { 
            return (
                <Link to={`/lists/${list.id}`} key={list.id}
                className="itemInfoLists"
                >
                    <img className={list.list_image ? `listImage${list.list_image}` : "listImage"}
                    src={`${baseImgUrl}/v1632672578/items_gold/${list.id}.png`}
                    alt={list.name1}></img>
                </Link>
            )})}
            </div>
            <p>Appears in {result.length > 1 ? `${result.length} lists including ` : null}<Link to={`/lists/${result[0].id}`}>{result[0].name1} {result[0].name2}</Link></p>      
        </div>
    );
};

export default ItemScreenListAppearances;