import React, { useState, useEffect } from 'react';
import CreateListItemRow from 'components/Create/CreateListItemRow';

const CreateListStepFour = ( { listIndex, listItemArray, setListItemArray } ) => {
  // const [listItemArray, setListItemArray] = useState([]);
  const [value, setValue] = useState(3);
  const [currentSelect, setCurrentSelect] = useState(0);

// console.log(listItemArray);

const handleValueChange = (event) => {
  setValue(Math.max(0, Math.min(1000, Number(event.target.value))));
};

const handleValueSubmit = () => {
  console.log("INSIDE HANDLEVALUESUBMIT");
  setListItemArray(
    [...Array(Number(value))].map((e, i) =>
    {return {index: i, itemId: 0, itemName: '', data: 0, data2: 0, type: 0, subtype: 0, datatype: 0, masterId: 0}}
    ))
}

const handleChange = (idx, el, value) => {
  // console.log(idx);
  const newItemArray = listItemArray.map(items => {
    // console.log(items.index)
      if (items.index !== idx) {
        // No change
        return items;
      } else if (el === "type" ) {
        // Return new figure via el
        return {
          ...items,
          [el]: value,
          subtype: 0,
        };
      } else {
        return {
          ...items,
          [el]: value,
        };
      }
    });
    // Re-render with the new array
    setListItemArray(newItemArray);
    // console.log(el);
    // if (el === "type"){
    //   console.log("SETTING TYPE IN HANDLECHANGE")
    //   setType(value);
    // }
}

// useEffect(() => {
//   console.log("USEEFFECT JUST RAN");
// }, []);

    return (
      <div className={listIndex === 3 ? "CreateListStepFour activeComponent" : "CreateListStepFour"}>
        <h1>Items Page</h1>
        {listItemArray.length === 0
        ?
        <>
          <p>How Many Items?</p>
          <input type="number" value={value} onChange={handleValueChange}></input>
          {value === 1000 && <p>Max number of rows is 1,000</p>}
          <button onClick={handleValueSubmit}>Go</button>
        </>
        :
        <>
        <div className="CreateListItemToolbar">
        <p>Rows = {listItemArray.length}</p>
          <button onClick={() => setListItemArray([])}>Start Again</button>
          <div className="addRemoveRows">
          <span onClick={() => {
            listItemArray.push({
              index: listItemArray.length, itemId: 0, itemName: '', data: 0, data2: 0, type: 0, subtype: 0, datatype: 0, masterId: 0
            })
            setListItemArray([...listItemArray]);
          }}>+</span>
          <span onClick={() => {
            listItemArray.pop()
            setListItemArray([...listItemArray]);
          }}>-</span>
          </div>
          </div>
          <div className="CreateListItems">
          {listItemArray.map(item => {
            return (
              <CreateListItemRow
              key={item.index}
              item={item}
              handleChange={handleChange}
              currentSelect={currentSelect}
              setCurrentSelect={setCurrentSelect}
            />
          )})}
          </div>
        </>
        }
      </div>
    );
};

export default CreateListStepFour;