import React, { useState, useEffect } from 'react';
import trackerApi from "api/tracker";

import 'screens/ItemScreen.css';

const ItemScreenLinks = ({itemId, subtypeName}) => {
  const [result, setResult] = useState(null);

  const baseImg = `https://res.cloudinary.com/checkmash/image/upload/v1632671325/items`;

  const getResult = async itemId => {
    const response = await trackerApi.get(`/links/${itemId}`);

    setResult(response.data);
  };

  useEffect(() => {
      getResult(itemId);
  }, []);

if (!result) { 
    return null;
}

  return (
    <div className="tags">
      <p className="tagText">{subtypeName}</p>
      {result.map(links => {
        console.log(links.showImage)
        return (
          <p className="tagText" key={links.linkId}>
            {links.showImage === 0
            ? links.item_name
            : <img key={links.linkId} src={`${baseImg}/${links.itemId}.png`} className="tagImg" alt={links.item_name}></img>
            }
          </p>
      )})}
    </div>
);
};

export default ItemScreenLinks;