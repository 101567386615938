import React, { useState } from 'react';
import Searchbar from 'components/Searchbar';
import CreateListMasterResults from './CreateListMasterResults';

const CreateListStepTwo = ( { listIndex, listMaster, setListMaster } ) => {
    const [search, setSearch] = useState('');

    return (
      <div className={listIndex === 1 ? "CreateListStepTwo activeComponent" : "CreateListStepTwo"}>
        <h1>Select Master</h1>
        <p>We want to keep items connected to each other</p>
        <p>For example, Paris is in France</p>
        <p>Stranger Things Season 4 is a part of Stranger Things</p>
        <p>Can this item be connected to an existing item?</p>
        <Searchbar
            setSearch={setSearch}  
            search={search} 
            placeholder="Filter by item name"
        />
        {search && search.length > 2 ?
        <CreateListMasterResults
          search={search}
          master={listMaster}
          setMaster={setListMaster}
        />
        : null
        }
      </div>
    );
};

export default CreateListStepTwo;