import React, { useState } from 'react';

import 'screens/HomeScreen.css';

import HomeScreenFeedTop from "components/Home/HomeScreenFeedTop";
import HomeScreenFeedCaroItems from "components/Home/HomeScreenFeedCaroItems";
import HomeScreenFeedCaroLists from "components/Home/HomeScreenFeedCaroLists";
import HomeScreenFeedDateTime from "components/Home/HomeScreenFeedDateTime";


import Heart from "icons/Heart";
import Comment from "icons/Comment";
import Share from "icons/Share";


const HomeScreenFeedEntry = ({ post }) => {
  const [numItems, setNumItems] = useState(null);

  return (
    <div key={post.id} className="FeedEntry">
    <HomeScreenFeedTop
      userId={post.user_id}
      user={post.uName}
      headerType={post.item_id ? "headerItem" : "headerList"}
      link={post.item_id ? "items" : "lists"}
      postId={post.id}
      numItems={numItems}
    />
    {post.list_id ?
    <HomeScreenFeedCaroItems
      postId={post.id}
      updatedTime={post.updated_at}
      setNumItems={setNumItems}
    />
    :
    <HomeScreenFeedCaroLists
      userId={post.user_id}
      postId={post.id}
      itemId={post.item_id}
      headerType={post.item_id ? "headerItem" : "headerList"}
    />
    }
    <div className="FeedResponses">
      <Heart />
      <Comment />
      <Share />
    </div>
    <HomeScreenFeedDateTime updatedAt={post.updated_at}/>
  </div>
  );
};

export default HomeScreenFeedEntry;
