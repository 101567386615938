import React, { useState, useEffect } from 'react';
import trackerApi from "api/tracker";

const AccountScreenTotals = ( { userId } ) => {
    const [result, setResult] = useState(null);

    const getResult = async () => {
        const response = await trackerApi.get(`/counts/${userId}`);
    
        setResult(response.data[0]);
    };
    useEffect(() => {
        getResult();
    }, [userId]);
    
    if (!result) { 
        return null;
    }

    return (
        <div className="topData">
            <p>{result.tickCount} Checks</p>
            <p>{result.listCount} Lists</p>
            <p>{result.followerCount} Followers</p>
            <p>{result.followingCount} Following</p>
        </div>
    );
};

export default AccountScreenTotals;