import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import trackerApi from "api/tracker";
import UserScreenListCounter from 'components/Account/AccountScreenListCounter';
import AccountScreenTrackingWith from 'components/Account/AccountScreenTrackingWith';

const AccountScreenTracking = ( { userId }) => {
    const [result, setResult] = useState(null);

    const baseImg = `https://res.cloudinary.com/checkmash/image/upload/`;

    const getResult = async () => {
        const response = await trackerApi.get(`/progress/${userId}`);
    
        setResult(response.data);
        // console.log(response.data);
    };
    useEffect(() => {
        getResult();
    }, [userId]);
    
    if (!result || result.length === 0) {
        return null;
    }

    // Todo working on - tracking items and lists, need to change baseImg path
    // depending on list or item
    // Could change this and use the master item from a list rather than the list itself
    // which would also allow me to check if the master item is present and
    // display things different, i.e. group Season 1 with main TV series

  return (
    <div className="resultSection">
      <h2>Items and Lists Tracking</h2>
      {result.map(item => {
        return (
          // <div className="itemRow" key={item.progressId} >
          <Link className="itemRow" to={`/${item.LoI}s/${item.id}`}>
            <img className="itemRowImage"
              src={`${baseImg}/${item.LoI === "item" ? `v1632671325/items/${item.path}.jpg` : `v1632672578/items_gold/${item.path}.png`}`}
              alt={item.itemName}>
            </img>
            <div className="itemRowDetails">
              {item.LoI === "list" ?
              <UserScreenListCounter
                  listId={item.id}
                  userId={userId}
              />
              : 
                <p>{item.name}</p>
              }
              <AccountScreenTrackingWith
                progressId={item.progressId}
                userId={userId}
              />
            </div>
          </Link>
          // </div>
        )})} 
  </div>
  );
};

export default AccountScreenTracking;