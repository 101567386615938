import React from 'react';

const ListFilterButton = ({ className, changeFilter, changeFilterValue, text }) => {
    return (
        <div className={className} onClick={() => changeFilter(changeFilterValue)}>
            <p>{text}</p>
        </div>
    );
};

export default ListFilterButton;
