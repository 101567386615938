import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import './Header.css';
// import ItemCategoriesScreen from "./screens/ItemCategoriesScreen";
import HomeScreen from "./screens/HomeScreen";
import ItemScreen from "./screens/ItemScreen";
import ListScreen from "./screens/ListScreen";
import CreateHubScreen from "./screens/CreateHubScreen";
// import CreateItemScreen from "./screens/CreateItemScreen";
import CreateListScreen from "./screens/CreateListScreen";
import AccountScreen from "./screens/AccountScreen";
import ExploreScreen from "screens/ExploreScreen";
import ExploreCategory from "components/Explore/ExploreCategory";
// import Header from "./Header";
import HeaderB from "./HeaderB";
import { ThemeProvider } from './context/ThemeContext';
import { UserContext } from './context/UserContext';

import TestScreen from "screens/TestScreen";

function App() {

const userId = 1;
const userName = "jono";

  return (
    <ThemeProvider>
      <UserContext.Provider  value={{userId, userName}}>
        <div className="App">         
          <BrowserRouter>
            <HeaderB />
            <div className="content">
              <Routes>
                <Route path="/" title="Seendone" element={<HomeScreen/>} exact />
                <Route path="/items/:itemId" element={<ItemScreen/>} exact/>
                <Route path="/lists/:listId" element={<ListScreen/>} exact/>
                <Route path="/create" element={<CreateHubScreen/>} exact/>
                <Route path="/create/list" element={<CreateListScreen/>} exact/>
                {/* <Route path="/create/item" element={<CreateItemScreen/>} exact/> */}
                <Route path="/users/:userName" element={<AccountScreen/>} exact />
                <Route path="/explore" element={<ExploreScreen/>} exact />
                <Route path="/explore/:category" element={<ExploreCategory/>} exact />
                <Route path="/test" element={<TestScreen/>} exact />
              </Routes>
            </div>
          </BrowserRouter>          
        </div>
      </UserContext.Provider>
    </ThemeProvider>
  );
}

export default App;
