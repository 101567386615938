import React from "react";
import { Link } from "react-router-dom";

import 'screens/ExploreScreen.css';
import ExploreGrid from "components/Explore/ExploreGrid";

const ExploreScreen = () => {

  return (
    <div className="ExploreScreen">
        <h1>Explore</h1>
      <div className="ExploreScreenFilters">
        <Link to={`/explore/films`}>
          <p>Films</p>
        </Link>
        <Link to={`/explore/tv`}>
          <p>TV</p>
        </Link>
        <Link to={`/explore/travel`}>
          <p>Travel</p>
        </Link>
        <Link to={`/explore/animal`}>
          <p>Animals</p>
        </Link>
        <Link to={`/explore/gaming`}>
          <p>Gaming</p>
        </Link>
      </div>
      <ExploreGrid
        category="all"  
      />
    </div>
  );
};

export default ExploreScreen;
