import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";

import { UserContext } from 'context/UserContext';
import trackerApi from "api/tracker";

import ItemScreenLinks from 'components/Item/ItemScreenLinks';
// import ItemScreenTable from 'components/ItemScreenTable';
import ItemScreenCheckData from 'components/Item/ItemScreenCheckData';
import ItemScreenUnchecked from 'components/Item/ItemScreenUnchecked';
import ItemScreenFriendsWith from 'components/Item/ItemScreenFriendsWith';
import ItemScreenListAppearances from 'components/Item/ItemScreenListAppearances';

import 'screens/ItemScreen.css';

const ItemScreen = () => {
  const [result, setResult] = useState(null);
  const [title, setTitle] = useState(document.title);

  document.title = title;
 
  const { userId } = useContext(UserContext);

  const { itemId } = useParams();

  const getResult = async () => {
    const response = await trackerApi.get(`/items/${itemId}/users/${userId}`);

    setResult(response.data[0]);
    setTitle(response.data[0].item_name);
};

useEffect(() => {
    getResult();
}, [itemId]);

if (!result) { 
    return null;
}

const baseImg = `https://res.cloudinary.com/checkmash/image/upload/v1632671325/items`;
const itemImg = `${baseImg}/${result.path}.jpg`;

const renderSwitch = (param) => {
  switch(param) {
    case 1: //date
    case 4: // year
    case 6: // year / no of parts
      return result.data;
    case 5: // yearspan
    return `${result.data} - ${result.data2}`;
    case 0:
    case null:
      return result.masterName;
    default:
    return null;
  }
};

// const catImg = `https://res.cloudinary.com/checkmash/image/upload/v1632666502/categories/png`;
    
  return (
    <div className="ItemScreen" key={window.location.pathname}>
      <div className="titleBox">
        <p className="title">{result.item_name}</p>
        <div className="titleInfo">
          <p>{renderSwitch(result.datatype)}</p>
          <p>{result.type_single}</p>
        </div>
      </div>
      <img className="itemImage" src={itemImg} alt={result.item_name}></img>
      <ItemScreenLinks
        key={itemId}
        itemId={itemId}
        subtypeName={result.subtype}
        typeName={result.type_single}
        itemTypeId={result.type_id}
      />
      <div className="itemInfo">
        {result.checkId == null ?
        <ItemScreenUnchecked
          itemId={itemId}
          initialprogressState={result.progressState == null ? false : result.progressState}
        />
        :
        <ItemScreenCheckData
          itemId={itemId}
          checkedDate={result.checkedDate}
        />
        }
        <ItemScreenFriendsWith
          itemId={itemId}
        />
        <ItemScreenListAppearances
          itemId={itemId}
        />
      </div>
    </div>
  );
};

export default ItemScreen;
