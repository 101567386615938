import React, { useState, useContext } from 'react';
import { UserContext } from "context/UserContext";
import trackerApi from "api/tracker";
import CheckCircle from "icons/CheckCircle";
import Add from "icons/Add";

const ItemScreenCheckData = ( { itemId, checkedDate } ) => {
  const { userId } = useContext(UserContext);
  const [checkState, setCheckState] = useState(true);

  const getResultItemCRUD = async () => {
    const response = await trackerApi.post(`ticks/${itemId}/${userId}/${checkState}`);
    // setCheckStatus(!checkStatus);
    setCheckState(response.data);
  };

var months_arr = ['January','February','March','April','May','June','July','August','September','October','November','December'];

var today = new Date();

var todayDay = String(today.getDate());
var todayMonth = months_arr[today.getMonth()];
var todayYear = today.getFullYear();
var todayFull = `${todayMonth} ${todayDay}, ${todayYear}`

var checkDate = new Date(checkedDate);

var checkDay = checkDate.getDate();
var checkMonth = months_arr[checkDate.getMonth()];
var checkYear = checkDate.getFullYear();
var checkFull = `${checkMonth} ${checkDay}, ${checkYear}`

  return (
    <div className="itemInfoFriends">
      <div className="itemInfoFriendsImages"
      onClick={() => {
        getResultItemCRUD()
    }}>
      {checkState === true
      ? <CheckCircle fill="green"/>
      : <Add />
    }   
      </div>
      <p>
        {checkState === true
          ? `${todayFull === checkFull ? `Checked today` : `Checked by you on ${checkFull}`}`
          : "Check Item"
        }
      </p>
    </div>
  );

   
};

export default ItemScreenCheckData;