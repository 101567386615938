import axios from "axios";
// import AsyncStorage from '@react-native-async-storage/async-storage';

const instance = axios.create({
  // Prod
  baseURL: "https://www.seendone.com/backend"
  // Dev (working on correct address...)
  // baseURL: "http://46.101.16.175:3000/backend"
});

// instance.interceptors.request.use(
//   async (config) => {
//     const token = await AsyncStorage.getItem('token');
//     if(token){
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (err) => {
//     return Promise.reject(err);
//   }
// );

export default instance;

// test(){
//   fetch('http://yourPCip:3000/users')
//     .then(response => response.json())
//     .then(users => console.warn(users))
// }