import React from 'react';
import CreateListItemDropdown from 'components/Create/CreateListItemDropdown';

const CreateListItemRow = ( { item, handleChange, currentSelect, setCurrentSelect } ) => {

    return (
      <div className="CreateListItemRow_v2">
        <div onClick={e => setCurrentSelect(currentSelect === item.index ? null : item.index)}>
        <h2>{item.index +1}</h2>
        <h3>{item.itemName === "" ? "Click to add info" : item.itemName}</h3>
        <p>{item.type}</p>
        <p>{item.subtype}</p>
        <p>{item.datatype}</p>
        <p>{item.data}</p>
        <p>{item.data2}</p>
        <p>{item.master}</p>
        </div>
        {currentSelect === item.index &&
        <>
         <div className="CreateListItemRow_Row">
          {/* <h2>{item.index +1}</h2> */}
          <h4>Item Name</h4>
          <input value={item.itemName} name="itemName" onChange={e =>
            handleChange(item.index, e.target.name, e.target.value)}>
          </input>
        </div>
        
        <div className="CreateListItemRow_Row">
          <h4>Category</h4>
          <CreateListItemDropdown
            field="type"
            label="Category"
            defaultV={item.type}
            handleChange={handleChange}
            path="types"
            index={item.index}
          />
          {item.type !== 0 ?
            <CreateListItemDropdown
              field="subtype"
              label="Sub-Category"
              defaultV={item.subtype}
              handleChange={handleChange}
              path={`item_subtypes/${item.type}`}
              type={item.type}
              index={item.index}
          />
          : null }
        </div>
        <div className="CreateListItemRow_Row">
          <h4>Data</h4>
          <CreateListItemDropdown
            field="datatype"
            label="Datatype"
            defaultV={item.datatype}
            handleChange={handleChange}
            path="item_datatypes"
            index={item.index}
          />
          {item.datatype !== 0 ?
            <div className="dataWithLabel">
              {item.datatype === 5
                ? <p>Year Start</p>
                : item.datatype === 6
                ? <p>Year</p>
                : null
              }
              <input value={item.data} name="data"
                onChange={e => handleChange(item.index, e.target.name, e.target.value)}>
              </input>
            </div>
          : null}
          {item.datatype === 5 || item.datatype === 6 ?
            <div className="dataWithLabel">
              {item.datatype === 5
                ? <p>Year End</p>
                : <p># of Parts</p>
              }
              <input value={item.data2} name="data2"
                onChange={e => handleChange(item.index, e.target.name, e.target.value)}>
              </input>
            </div>
          : null }
        </div>
        <div className="CreateListItemRow_Row">
          <h4>Master</h4>
          <p>Search to be added...</p>
        </div>
        </>
      }
      </div>
    );
};

export default CreateListItemRow;