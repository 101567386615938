import React, { useContext } from 'react';
import { UserContext } from "context/UserContext";
import trackerApi from "api/tracker";
import CheckCircleFull from 'icons/CheckCircleFull';
import Add from 'icons/Add';

const ListItem = ({ itemId, listId, listTotalDiff, listLastUpdate, itemName, itemType, itemData, itemData2, itemDatatype, itemHasImage, itemImage, tickId, isActive, setActive, getResultItems }) => {
  const { userId }  = useContext(UserContext);

  const getResultItemCRUD = async () => {
      const response = await trackerApi.post(`activeLists/${listId}/${isActive ? 1 : 0}/${listTotalDiff}/${listLastUpdate}/${itemId}/${userId}/${tickId ? 1 : 0}`);
      getResultItems();
      setActive(userId);
    };

const baseImg = `https://res.cloudinary.com/checkmash/image/upload/`;

var displayData = [1, 4, 5, 6]; // date, year, year_total
   
  return (
    <div className="listItemBox" key={itemId}>
      <img className="listItemImage" src={itemHasImage === 1 ? baseImg + 'v1632671325/items/' + itemImage + '.jpg' : baseImg + 'v1632666502/categories/png/' + itemType + '.png' } alt={itemName}></img>
      <div className="listItemDetails">
        <p>{itemName} {displayData.includes(itemDatatype)
          ? <span>({itemData}
            {itemDatatype === 5 ? ` - ${itemData2}` : null})</span>
          : null}
        </p>
      </div>
      {tickId
        ?
        <div className="itemChecked"
          onClick={() => {
            getResultItemCRUD()
          }}
        >
          <p>Checked</p>
          <CheckCircleFull/>
        </div>
        :
        <div className="itemUnchecked"
          onClick={() => {
            getResultItemCRUD()
          }}
        >
          <p>Add</p>
          <Add/>
        </div>
      }
      </div>
  );
};

export default ListItem;
