import React, { useState, useEffect } from 'react';
import trackerApi from "api/tracker";

const CreateFindMasterResults = ( { search, master, setMaster } ) => {
    // const [search, setSearch] = useState('harry');
    const [result, setResult] = useState(null);
    const [select, setSelect] = useState(false);

    const getResult = async search => {
        const response = await trackerApi.get(`/create/search/items/${search}`);
  
        setResult(response.data);
    };

    useEffect(() => {
        getResult(search);
    }, [search]);

    const baseImg = `https://res.cloudinary.com/checkmash/image/upload/v1632671325/items`;

    if(!result){
        return null;
    }

    // console.log(select);

    return (
            <div className="CreateFindMasterResults">
                <p>{result.length} items</p>
                {result.map(item => {
                    
          return (
            <div className="CreateFindMasterResultsRow" key={item.id}
            // Todo - Not working
              style={{backgroundColor: item.id !== select ? "none" : "red"}}

              onClick={() => {
                setSelect(select === item.id ? false : item.id)
                // console.log(item.id)
                setMaster(master.masterId === item.id ? { masterId: null, masterName: null } : {  masterId: item.id, masterName: item.name } )
              }}
              >
              <img src={`${baseImg}/${item.id}.jpg`}></img>
              <div>
                <p>{item.name}</p>
                {item.data ?
                <p>{item.data}</p>
                : null
                }
                <p>{item.typeName}</p>
                {item.subtype ?
                <p>{item.subtype}</p>
                : null
                }
              </div>
              </div>
          )})}
            </div>
    );
};

export default CreateFindMasterResults;