import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import trackerApi from "../api/tracker";

const SearchResults = ( { search, setSearch }) => {
    const [result, setResult] = useState(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setSearch("");
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const getResult = async () => {
    const response = await trackerApi.get(`/search/${search}`);

    setResult(response.data);
    };

useEffect(() => {
    getResult();
}, [search]);

if (!result) { 
    return null;
}

  return (
      <div className="SearchResults" ref={wrapperRef}>
        {/* <h1>{search}</h1> */}
        {result.length > 0 ? result.map(results => {
            return (
                <Link to={`/${results.tableName}/${results.id}`} key={results.name} onClick={() => setSearch('')}>
                    <p>{results.name}</p>
                </Link>
            )
        })
        : <p>No results</p>
        }
      </div>
     
  );
};

export default SearchResults;
