import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "context/UserContext";
import trackerApi from "api/tracker";
import Searchbar from 'components/Searchbar';
import ListItem from 'components/List/ListItem';
import ListFilterButton from 'components/List/ListFilterButton';

const ListItems = ({ listId, activeStatus, listLastUpdate }) => {
  const { userId } = useContext(UserContext);

  const [result, setResult] = useState(null);
  const [searchFilter, setSearchFilter] = useState('');
  const [checkFilter, setCheckFilter] = useState(null);
  const [isActive, setActive] = useState(activeStatus);

  const getResultItems = async () => {
    const response = await trackerApi.get(`/lists/items/${listId}/users/${userId}`);

    setResult(response.data);
};
useEffect(() => {
    getResultItems();
}, []);

if (!result) { 
    return null;
}

const checkArray = result.map(checks => {
  return checks.tickId
})

const listLength = result.length;
const checkTotal = checkArray.filter(Number).length;
const checkPercentage = Math.round(checkTotal / listLength * 100);

const displayItems = (checkFilter === 1)
? result.filter(result => result.tickId && result.name.toLowerCase().includes(searchFilter.toLowerCase()))
: (checkFilter === 2)
? result.filter(result => !result.tickId && result.name.toLowerCase().includes(searchFilter.toLowerCase()))
: result.filter(result => result.name.toLowerCase().includes(searchFilter.toLowerCase()));

const changeFilter = (filter) => {
  if(checkFilter === filter){
    setCheckFilter(null);
    if(filter === null){
      setSearchFilter('');
    }
  } else {
    setCheckFilter(filter);
    if(filter === null){
      setSearchFilter('');
    }
  }
};

// console.log(isActive);

  return (
    <>
      <div className="listInfo">
          <p>{listLength} items</p>
          {isActive === userId && listLength === checkTotal ?
          <p>List Complete!</p>
          : isActive === userId ?
          <>
            <p>{checkTotal === userId ? `${checkTotal} check` : `${checkTotal} checked`}</p>
            <p>{checkPercentage}% complete</p>
          </>
          :
          <p>List Inactive</p>
          }
      </div>
      <div className="filters">
        <ListFilterButton 
          className={!checkFilter && searchFilter === '' ? "selected" : ""}
          changeFilter={changeFilter}
          changeFilterValue={null}
          text="SHOW ALL"
        />
        <ListFilterButton 
          className={checkFilter === 1 ? "selected" : ""}
          changeFilter={changeFilter}
          changeFilterValue={1}
          text="CHECKED ONLY"
        />
        <ListFilterButton 
          className={checkFilter === 2 ? "selected" : ""}
          changeFilter={changeFilter}
          changeFilterValue={2}
          text="UNCHECKED ONLY"
        />
        <Searchbar
          setSearch={setSearchFilter}  
          search={searchFilter}  
          placeholder={null}
          height={20}  
          className={"listFilterSearch"}
        />
        {/* {displayItems.length !== listLength && */}
          <p>Items shown: {displayItems.length}/{listLength}</p>
        {/* } */}
      </div>
      <div className="itemsContainer">
        {displayItems.length > 0 ? displayItems.map(item => {
          return (
            <ListItem 
              key={item.id}
              getResultItems={getResultItems}
              listId={listId}
              listTotalDiff={listLength - checkTotal}
              itemId={item.id}
              itemName={item.name}
              itemType={item.type}
              itemData={item.data}
              itemData2={item.data2}
              itemDatatype={item.datatype}
              itemHasImage={item.has_image}
              itemImage={item.path}
              tickId={item.tickId}
              isActive={isActive}
              setActive={setActive}
              listLastUpdate={listLastUpdate}
            />
          )})
        : checkFilter === 1 ? <p>There are no checked items</p>
        : <p>There are no unchecked items</p>
        }
      </div>
      </>
  );
};

export default ListItems;