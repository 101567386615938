import React from "react";

// import CreateRow from "components/Create/CreateRow";
// import CreateRowp from "components/Create/CreateRowp";
// import CreateSelectOption from "components/Create/CreateSelectOption";

import CreateDropdown from "components/Create/CreateDropdown";

const CreateListStepOne = ( { listIndex, setListName, setSubtitle, setListType, setListSource } ) => {

  return (
        <div className={listIndex === 0 ? "CreateListStepOne activeComponent" : "CreateListStepOne"}>
          <h1>List Details</h1>
          <div>
        <p>Category</p>
        {/* <input onChange={e => setListType(e.target.value)}></input> */}
        <CreateDropdown
          setListType={setListType}
        />
        </div>
        <div>
          <p>List Name</p>
          <div>
            <input onChange={e => setListName(e.target.value)}></input>
            <p>e.g. Harry Potter</p>
          </div>
        </div>
        <div>
          <p>Subtitle</p>
          <div>
            <input onChange={e => setSubtitle(e.target.value)}></input>
            <p>description of what it is, e.g. Book Series</p>
          </div>
        </div>
        <div>
          <p>Source</p>
          <div>
            <input onChange={e => setListSource(e.target.value)}></input>
            <p>e.g. https://en.wikipedia.org/wiki/Harry_Potter</p>
          </div>
        </div>
      </div>
  );
};

export default CreateListStepOne;
