import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import trackerApi from "api/tracker";
import 'screens/HomeScreen.css';

const HomeScreenFeedCaroLists = ( { itemId, userId } ) => {
    const [result, setResult] = useState(null);

    const getResult = async () => {
    const response = await trackerApi.get(`/users/${userId}/lists/${itemId}`);

    setResult(response.data);
};
useEffect(() => {
    getResult();
}, []);

if (!result || result.length < 1) { 
    return null;
}

  return (
    <div className="CaroLists">
        <h2>List Progress</h2>
        {result.map(counter => {
            const progressPercentage = Math.round(counter.tickcount / counter.listcount * 100);

            return (
            <Link className="CaroListsProgress" key={counter.id} to={`lists/${counter.id}`}>
                <div className="CaroListsName">
                    <p>{counter.list_name} {counter.subtitle}</p>
                </div>
                <div className="CaroListsTotal">
                    <p>
                    {(counter.tickcount === counter.listcount ? 'Complete!' : 
                        `${counter.tickcount} out of ${counter.listcount}`
                        )}
                    </p>
                    <div className="listProgress">
                        <div className={counter.tickcount === counter.listcount ? "listProgressBarComplete" : "listProgressBar"}
                        style={{"width" : `${progressPercentage}%`}}
                        >
                            <p>{progressPercentage}%</p>
                        </div>
                    </div>
                </div>
            </Link>
        )})}
    </div>
  );
};

export default HomeScreenFeedCaroLists;