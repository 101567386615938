import React, { useState, useContext }  from 'react';
import { UserContext } from "context/UserContext";
import trackerApi from "api/tracker";

const CreateListStepFive = ( { listIndex, listName, subtitle, listType, listSource, listMaster, listItemArray, listImage } ) => {
    const { userId }  = useContext(UserContext);
    // const [listId, setListId] = useState(false);
    const [listSubmit, setListSubmit] = useState(false);
    const fullListArray = [];
    // const listImageArray = listImage && listImage.split("/");
    const listImageFilename = listImage && listImage.split("/")[1];

    console.log(listImageFilename)

    const getResultItemCRUD = async () => {
        // PART ONE - CREATE NEW LIST
        const response = await trackerApi.post(`submitList/${userId}/${listName}/${subtitle}/${listType.listTypeId}/${listSource}/${listMaster.masterId}/${listImageFilename}`);
        const response_image = await trackerApi.post(`submitListImage/${response.data.insertId}/${listImageFilename}`);

        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
      } else {
       
        // PART TWO - CREATE NEW ITEMS
        // loop over items

        listItemArray.map(items => {
          // check itemId is 0, otherwise ignore as is existing item
          if(items.itemId === 0 ) {
            // insert for each new item, return new id
            insertItemCRUD(items, response.data.insertId);
          } else {
            fullListArray.push(items.itemId);
            console.log(fullListArray);
          }
        })
          setListSubmit(true);
        }
      };

    
            const insertItemCRUD = async (items, listId) => {
            // insert for each new item, return new id
            const response_items = await trackerApi.post(`submitItem/${items.itemName}/${userId}/${items.type}/${items.subtype}/${items.data}/${items.data2}/${items.datatype}/${items.masterId}`);
            // PART THREE - ADD ALL WITH LIST_ID TO FULL_LISTS
            const response_fullList = await trackerApi.post(`submitFullList/${response_items.data.insertId}/${listId}`);
          }

      console.log(listItemArray);

    return (
      <div className={listIndex === 4 ? "CreateListStepFive activeComponent" : "CreateListStepFive"}>
        <h1>Confirmation</h1>
        {listImage && (
        <img alt="selected List Image" width={"250px"}
          src={`https://res.cloudinary.com/checkmash/image/upload/v1669381297/${listImage}`}
        />
        )}
        <div>
            <p>List Category</p>
            <p>{listType.listTypeName} ({listType.listTypeId})</p>
        </div>
        <div>
            <p>List Name</p>
            <p>{listName}</p>
        </div>
        <div>
            <p>Subtitle</p>
            <p>{subtitle}</p>
        </div>
        <div>
            <p>Source</p>
            <p>{listSource}</p>
        </div>
        <div>
            <p>Master</p>
            <p>{listMaster.masterName} ({listMaster.masterId})</p>
        </div>
        <div>
            <p>Items</p>
            {listItemArray.map(items => {
                return (
                    <p key={items.index}>{items.itemName}</p>
                )
            })}
        </div>
        <button
          onClick={() => {
            getResultItemCRUD()
          }}
        >
        Add List and Items</button>
        {listSubmit && <p>List Added!</p>}
      </div>
    );
};

export default CreateListStepFive;