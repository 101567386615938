import React, { useState, useEffect } from 'react';
import trackerApi from 'api/tracker';

const CreateListItemDropdown = ( { path, field, label, defaultV, handleChange, index } ) => {
    const [result, setResult] = useState(null);
  
  async function getResult() {
    const response = await trackerApi.get(`/create/${path}`);

    setResult(response.data);
  }
  
  useEffect(() => {
      getResult();
      console.log("RAN USEEFFECT IN DROPDOWN")
  }, [path]);

  if (!result || result.length < 1) { 
      return null;
  }

  // if(index === 0 && field === "subtype") {
  //   console.log(defaultV);
  // }

  // Currently at - working, however if include disabled on value 0 it does not return to this on rerender?
 

    return (
            <select defaultValue={defaultV} name={field} onChange={ (e) => {handleChange(index, e.target.name, Number(e.target.value))}}>
                <option value={0} disabled >Select {label}</option>
                {result.map(item => {
                    return (
                        <option key={item.id}  value={item.id}>{item.field}</option>
                    )})}
            </select>
    );
};

export default CreateListItemDropdown;