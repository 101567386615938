import React from "react";
import { Link } from "react-router-dom";

import 'screens/Create.css';
import List from 'icons/List.js';
import CheckCircle from 'icons/CheckCircle.js';

const CreateHubScreen = () => {

  return (
    <div className="CreateHubScreen">
      <div className="optionBoxA" >
        <Link to={`/create/list`}>
          <List />
          <p>Create List</p>
        </Link>
      </div>
      <div className="optionBoxB">
        {/* <Link to={`/create/item`}> */}
        <CheckCircle />
          <p>Create Item</p>
        {/* </Link> */}
      </div>
    </div>
  );
};

export default CreateHubScreen;
