import React, { useState, useEffect, useContext } from 'react';
import 'screens/HomeScreen.css';
import trackerApi from "api/tracker";
// import { UserContext } from '../context/AuthContextCreate';
import { UserContext } from 'context/UserContext';
import HomeScreenFeedEntry from "../components/Home/HomeScreenFeedEntry";


const HomeScreen = () => {
  const [result, setResult] = useState(null);
  const { userId } = useContext(UserContext);

  const getResult = async userId => {
    const response = await trackerApi.get(`/ticks/${userId}`);
    setResult(response.data);
};
useEffect(() => {
    getResult(userId);
}, []);

if (!result) { 
    return null;
}

  return (
    <div className='home'>
      {result.map(post => {
        return (
          <HomeScreenFeedEntry
            key={post.id}
            post={post}
          />
      )})} 
    </div>
  );
};

export default HomeScreen;