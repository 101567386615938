import { createContext, useContext, useState, useLayoutEffect } from "react";
const ThemeContext = createContext();
const ThemeProvider = ({ children }) => {
const initialTheme = () => localStorage.getItem("userTheme");
const [theme, setTheme] = useState(initialTheme);
const toggleTheme = () =>
    setTheme((theme) => (theme === "light" ? "dark" : "light"));
useLayoutEffect(() => {
    localStorage.setItem("userTheme", theme);
if (theme === "light") {
      document.body.classList.remove("dark");
    } else {
      document.body.classList.add("dark");
    }
  }, [theme]);
return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
const useTheme = () => {
  const context = useContext(ThemeContext);
if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
return context;
};
export { ThemeProvider, useTheme };