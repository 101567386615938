import * as React from "react"

const Menu = (props) => (
    <svg className={props.class} viewBox="0 0 100 80" {...props}>
        <rect width="80" height="5"></rect>
        <rect y="30" width="80" height="5"></rect>
        <rect y="60" width="80" height="5"></rect>
    </svg>
)

export default Menu