
import { useParams } from "react-router-dom";

import 'screens/ExploreScreen.css';
import ExploreGrid from "components/Explore/ExploreGrid";

const ExploreCategory = () => {
    const { category } = useParams();

  return (
        <div className="ExploreCategory">
            <h1>{category}</h1>
            <ExploreGrid
                category={category}  
            />
      </div>
  );
};

export default ExploreCategory;
