import React, { useState, useEffect, useContext } from 'react';
import trackerApi from "api/tracker";

const TestScreen = () => {
  const [result, setResult] = useState(null);
  const [resultTwo, setResultTwo] = useState(null);

  const userId = 1;
  const itemId = 10;

  async function getCategory() {
    const response = await trackerApi.get(`/items/${itemId}/users/${userId}`);
    if (response.status !== 200) {
        throw new Error(`HTTP error: ${response.status}`);
    } else {
        // setResult(response.data[0]);
        const typeId = response.data[0].type_id;
        // console.log(response.data[0]);
        const response_two = await trackerApi.get(`/types/${typeId}`);
        setResult(response.data[0]);
        setResultTwo(response_two.data[0]);
        return response.data[0];
      }
  }

useEffect(() => {
    // getResult();
    // const promise = fetchProducts();
    // fetchProducts();
    const promise = getCategory();
    // promise.then((data) => console.log(data));
}, [itemId]);

if (!resultTwo) { 
    return null;
}
    
  return (
    <div>
    <p>So you want to do a test?</p>
    <p>{result.item_name}</p>
    <p>{resultTwo.type}</p>
    </div>
  );
};

export default TestScreen;
