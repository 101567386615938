import React, { useState } from "react";

import CreateListStepOne from "components/Create/CreateListStepOne";
import CreateListStepTwo from "components/Create/CreateListStepTwo";
import CreateListStepThree from "components/Create/CreateListStepThree";
import CreateListStepFour from "components/Create/CreateListStepFour";
import CreateListStepFive from "components/Create/CreateListStepFive";

const CreateListScreen = () => {
  const [listIndex, setIndex] = useState(0);

  const [listName, setListName] = useState(null);
  const [subtitle, setSubtitle] = useState(null);
  const [listType, setListType] = useState({
    listTypeId: null, listTypeName: null
  });
  const [listSource, setListSource] = useState(null);
  const [listMaster, setListMaster] = useState({
    masterId: null, masterName: null
  });
  const [listItemArray, setListItemArray] = useState([]);
  const [listImage, setListImage] = useState(null);

  // console.log(listItemArray);

  return (
    <div className="CreateListScreen">
      <CreateListStepOne
        setListName={setListName}
        setSubtitle={setSubtitle}
        setListType={setListType}
        setListSource={setListSource}
        listIndex={listIndex}
        />
      <CreateListStepTwo
        listMaster={listMaster}
        setListMaster={setListMaster}
        listIndex={listIndex}
      />
      <CreateListStepThree
        listIndex={listIndex}
        listImage={listImage}
        setListImage={setListImage}
      />
      <CreateListStepFour
        listIndex={listIndex}
        listItemArray={listItemArray}
        setListItemArray={setListItemArray}
      />
      <CreateListStepFive
        listIndex={listIndex}
        listName={listName}
        subtitle={subtitle}
        listType={listType}
        listSource={listSource}
        listMaster={listMaster}
        listItemArray={listItemArray}
        setListItemArray={setListItemArray}
        listImage={listImage}
      />
      {listIndex > 0 && listIndex < 4
      ? 
      <div className="stepButtons">
        <button onClick={() => setIndex(listIndex -1)}>Prev</button>
        <button onClick={() => setIndex(listIndex +1)}>Next</button>
      </div>
      : listIndex < 1 ?
      <div className="stepButtons">
        <button onClick={() => setIndex(listIndex +1)}>Next</button>
      </div>
      : 
      <div className="stepButtons">
        <button onClick={() => setIndex(listIndex -1)}>Prev</button>
        <button onClick={() => console.log("Finished")}>Finish</button>
      </div>
      }
     
      <div className="pagination">
        <span className={listIndex === 0 ? "active" : undefined} onClick={() => setIndex(0)}></span>
        <span className={listIndex === 1 ? "active" : undefined} onClick={() => setIndex(1)}></span>
        <span className={listIndex === 2 ? "active" : undefined} onClick={() => setIndex(2)}></span>
        <span className={listIndex === 3 ? "active" : undefined} onClick={() => setIndex(3)}></span>
        <span className={listIndex === 4 ? "active" : undefined} onClick={() => setIndex(4)}></span>
      </div>
    </div>
  );
};

export default CreateListScreen;
