import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";

import trackerApi from "api/tracker";
import ListItems from 'components/List/ListItems';

import 'screens/ListScreen.css';
import { UserContext } from 'context/UserContext';

const ListScreen = () => {
  const [result, setResult] = useState(null);
  const [title, setTitle] = useState(document.title);

  document.title = title;

  const { userId } = useContext(UserContext);

  const { listId } = useParams();

  const goldImg = `https://res.cloudinary.com/checkmash/image/upload/v1632672578/items_gold`;

  const getResult = async () => {
      const response = await trackerApi.get(`/lists/${listId}/users/${userId}`);

      setResult(response.data[0]);
      setTitle(response.data[0].list_name);
  };
  useEffect(() => {
      getResult();
  }, []);

  if (!result) { 
      return null;
  }

  return (
    <div className="ListScreen">
      <h1 className="listTitle">{result.list_name} {result.subtitle}</h1>
      <img className={result.list_image === 1 ? "headerPNG" : "headerImage"} src={goldImg + '/' + result.path + '.png'} alt={result.list_name}></img>
      <ListItems
        listId={listId}
        userId={userId}
        activeStatus={result.active}
        getResult={getResult}
        listLastUpdate={result.updated_at}
      />
    </div>
  );
};

export default ListScreen;
