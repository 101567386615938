import React, { useState, useRef, useEffect } from "react";

const CreateListStepThree = ( { listIndex, listImage, setListImage } ) => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget({
        cloudName: 'checkmash',
        uploadPreset: 'sd_upload',
        folder: "items_gold",
        multiple: false,
        clientAllowedFormats: ["jpg", "jpeg", "png"],
        maxImageFileSize: 2000000,
        maxImageWidth: 2000,
    }, function(error, result) {
        if(result.event === "success"){
            setListImage(result.info.public_id);
            console.log(result.info);
        }
    })
  }, [])

  return (
    <div className={listIndex === 2 ? "CreateListStepThree activeComponent" : "CreateListStepThree"}>
      <h1>Upload Image</h1>
      {listImage && (
        <img alt="selected List Image" width={"250px"} src={`https://res.cloudinary.com/checkmash/image/upload/v1669381297/${listImage}`} />
      )}
      <button onClick={() => widgetRef.current.open()}>
        {listImage ? "Replace Image" : "Upload Image" }
      </button>
    </div>
  );
};

export default CreateListStepThree;