import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import 'screens/HomeScreen.css';
import trackerApi from "api/tracker";


const HomeScreenFeedTop = ({ userId, user, headerType, link, postId, numItems }) => {
  const [result, setResult] = useState(null);
  
  async function getResult() {
      const response = await trackerApi.get(`/${headerType}/${postId}`);
      setResult(response.data[0]);
  }
  
  useEffect(() => {
      getResult();
  }, []);

  if (!result) { 
      return null;
  }

  const displayData = [1, 4, 5, 6]; // date, year, year_total
  const baseImgUrl = `https://res.cloudinary.com/checkmash/image/upload`;
  const userImg = `${baseImgUrl}/v1632660254/users/${userId}.jpg`;
  const itemImg = "v1632671325/items";
  const listImg = "v1632672578/items_gold";

  return (
    <>
      <div className='FeedTop'>
        <img className="FeedTopUserImg" src={userImg} alt="User"></img>
        <div className="FeedTopPostDetails">
        <Link to={"users/" + user}>{user}</Link>
            <Link to={`${link}/${result.id}`}>
              {headerType === "headerItem" ? "checked " : numItems > 1 ? `added ${numItems} items to ` : `added ${numItems} item to `}
              <span>{result.name}</span>
              {headerType === "headerItem" && displayData.includes(result.datatype)
              ? <span> ({result.data})</span>
              : null}
            </Link>
      </div>
      </div>
      <Link className="FeedImage" to={`${link}/${result.id}`}>
        <img src={`${baseImgUrl}/${headerType === "headerItem" ? itemImg : listImg}/${result.path}.png`}
        alt="Item"></img>
      </Link>
    </>
  );
};

export default HomeScreenFeedTop;
