import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "context/UserContext"
import { Link } from "react-router-dom";
import trackerApi from "api/tracker";

const ItemScreenFriendsWith = ( { itemId } ) => {
    const { userId }  = useContext(UserContext);
    const [result, setResult] = useState(null);

    const getResult = async () => {
        const response = await trackerApi.get(`/friends/${userId}/items/${itemId}`);
        setResult(response.data);
};

useEffect(() => {
    getResult();
}, []);

if (!result || result.length < 1) { 
    return null;
}

const baseImgUrl = `https://res.cloudinary.com/checkmash/image/upload`;

    return (
    <div className="itemInfoFriends">
        <div className="itemInfoFriendsImages">
            {result.map(list => {
                    return (
                        <Link to={`/users/${list.id}`} key={list.id}>
                            <img
                                className="userImage"
                                src={`${baseImgUrl}/v1632660254/users/${list.id}.jpg`}
                                alt={list.name1}>
                            </img>
                        </Link>
                    )})}
        </div>
            <p>Checked by
                <Link to={`/users/${result[0].id}`}> {result[0].name1}</Link>
                {result.length > 1
                ? ` and ${result.length-1} other${result.length > 2 ? `s` : ``}`
                : null
                }
            </p>
    </div>
    );
};

export default ItemScreenFriendsWith;