import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import trackerApi from "api/tracker";

import 'screens/ExploreScreen.css';

const ExploreGrid = ( { category } ) => {
    const [result, setResult] = useState(null);

    const getResult = async () => {
        const response = await trackerApi.get(`/explore/${category}`);
        setResult(response.data);
    };

    useEffect(() => {
        getResult();
    }, []);

    if (!result || result.length < 1) { 
        return (
            <p>No results</p>
        );
    }

    const baseImg = `https://res.cloudinary.com/checkmash/image/upload/`;

  return (
        <div className="ExploreGrid">
        {result.map(item => {
            const keyId = item.LoI + item.id;
            const imgPath = `${baseImg}${item.LoI === "Item" ? `v1632671325/items/${item.path}.jpg` : `v1632672578/items_gold/${item.path}.png`}`
            return (
                    <Link key={keyId} to={`/${item.LoI === "Item" ? `items` : `lists`}/${item.id}`}>
                        <img className="ExploreGridImage" src={imgPath} alt={result.name}></img>
                        <p>{item.name}</p>
                    </Link>
            )
        })}
      </div>
  );
};

export default ExploreGrid;
