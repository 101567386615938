import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import trackerApi from "api/tracker";

const AccountScreenCompleted = ( { userId } ) => {
    const [result, setResult] = useState(null);

    const goldImg = `https://res.cloudinary.com/checkmash/image/upload/v1632672578/items_gold`;
    const baseImg = `https://res.cloudinary.com/checkmash/image/upload/v1632660357/categories/png`;

    const getResult = async () => {
        const response = await trackerApi.get(`/active_lists/${userId}`);
    
        setResult(response.data);
        // console.log(response.data);
    };
    useEffect(() => {
        getResult();
    }, [userId]);
    
    if (!result || result.length === 0) { 
        return null;
    }
   
    return (
        <div className="completed">
            <h2>Completed Lists</h2>
            {result.map(list => {
            return (
            <Link className="completedItem" to={`/lists/${list.listId}`} key={list.listId}>
                <img className="completedItemImage" src={`${baseImg}/${list.typeId}.png`} alt={list.listName}></img>
                <p>{list.listName} {list.subtitle}</p>
            </Link>
        )})}
        </div>
        );
};

export default AccountScreenCompleted;