import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import 'screens/HomeScreen.css';
import trackerApi from "api/tracker";

const HomeScreenFeedCaroItems = ( { postId, updatedTime, setNumItems } ) => {
  const [result, setResult] = useState(null);
  
  async function getResult() {
      const response = await trackerApi.get(`/itemsarray/${postId}/${updatedTime}`);
      setResult(response.data);
      setNumItems(response.data.length)
  }
  
  useEffect(() => {
      getResult();
  }, []);

  if (!result || result.length < 1) { 
      return null;
  }

  return (
    <div className="CaroItems">
      <h2>Items Recently Checked</h2>
      <div className={result.length > 2 ? "CaroItemsMulti" : "CaroItemsSingle"}>
      {result.map(item => {
        const baseImgUrl = `https://res.cloudinary.com/checkmash/image/upload`;
        const itemImg = `${baseImgUrl}/v1632671325/items/${item.path}.png`;
            return (
            <Link className="CaroItemsItem" key={item.id} to={`/items/${item.id}`}>
              <img src={itemImg} alt={item.name}></img>
              <p key={item.id}>{item.name}</p>
            </Link>
            )})}
      </div>
    </div>
    );

};

export default HomeScreenFeedCaroItems;